/*
Verb tables, each containing a set of verb conjugations.
    -id: Unique primary key in verbTables
    -form: Verb form. See OptionsList.js for list of possible options.
    -weakness: Verb weakness. See OptionsList.js for list of possible options.
    -dummyRoots: List of root letters in the example verb conjugated.
    -rootIndices: List of indices at which root letter appears in the infinitive form.
        e.g: the verb فَعَلَ contains dummy roots ف-ع-ل at positions 0, 2 and 4 respectively.
    -pastVowel: (form I only) Vowelling of middle letter in the active perfect forms. 
    -presentVowel: (form I only) Vowelling of middle letter in the active imperfect forms.
        Vowellings are denoted by either "a" for fatha, "i" for kasra, or "u" for dhamma.
    -conjugations: dictionary of verb conjugations with the following nested structure.
        1st level: mood/tense (either perfect, indicative, subjunctive, jussive or imperative)
        2nd level: voice (either active or passive)
        3rd level: person, number and gender (see notes below)
            
Denoting person, number and gender:
    A three character code is used to denote a particular combination of person, number and gender.
        The first character denotes person ("1", "2" or "3" for first, second and third person respectively)
        The second character denotes number ("s", "d" or "p" for singular, dual and plural respectively)
        The third character denotes gender ("m" for masculine or "f" for feminine)
    For example, "3sm" denotes the third-person singular masculine.
*/

export const verbTables = [
    {
        id: 0,
        form: "I",
        weakness: "sound",
        dummyRoots: ['ف', 'ع', 'ل'],
        rootIndices: [0, 2, 4],
        pastVowel: "a",
        presentVowel: "a",
        conjugations: {
            perfect: {
                active: {
                    "3sm": "فَعَلَ",
                    "3sf": "فَعَلَتْ",
                    "2sm": "فَعَلْتَ",
                    "2sf": "فَعَلْتِ",
                    "1s": "فَعَلْتُ",
                    "3dm": "فَعَلا",
                    "3df": "فَعَلَتا",
                    "2d": "فَعَلْتُما",
                    "3pm": "فَعَلُواْ",
                    "3pf": "فَعَلْنَ",
                    "2pm": "فَعَلْتُمْ",
                    "2pf": "فَعَلْتُنَّ",
                    "1p": "فَعَلْنا",
                },
                passive: {
                    "3sm": "فُعِلَ",
                    "3sf": "فُعِلَتْ",
                    "2sm": "فُعِلْتَ",
                    "2sf": "فُعِلْتِ",
                    "1s": "فُعِلْتُ",
                    "3dm": "فُعِلا",
                    "3df": "فُعِلَتا",
                    "2d": "فُعِلْتُما",
                    "3pm": "فُعِلُواْ",
                    "3pf": "فُعِلْنَ",
                    "2pm": "فُعِلْتُمْ",
                    "2pf": "فُعِلْتُنَّ",
                    "1p": "فُعِلْنا",
                },
            },
            indicative: {
                active: {
                    "3sm": "يَفْعَلُ",
                    "3sf": "تَفْعَلُ",
                    "2sm": "تَفْعَلُ",
                    "2sf": "تَفْعَلِينَ",
                    "1s": "أَفْعَلُ",
                    "3dm": "يَفْعَلانِ",
                    "3df": "تَفْعَلانِ",
                    "2d": "تَفْعَلانِ",
                    "3pm": "يَفْعَلُونَ",
                    "3pf": "يَفْعَلْنَ",
                    "2pm": "تَفْعَلُونَ",
                    "2pf": "تَفْعَلْنَ",
                    "1p": "نَفْعَلُ",
                },
                passive: {
                    "3sm": "يُفْعَلُ",
                    "3sf": "تُفْعَلُ",
                    "2sm": "تُفْعَلُ",
                    "2sf": "تُفْعَلِينَ",
                    "1s": "أُفْعَلُ",
                    "3dm": "يُفْعَلانِ",
                    "3df": "تُفْعَلانِ",
                    "2d": "تُفْعَلانِ",
                    "3pm": "يُفْعَلُونَ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُونَ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلُ",
                },
            },
            subjunctive: {
                active: {
                    "3sm": "يَفْعَلَ",
                    "3sf": "تَفْعَلَ",
                    "2sm": "تَفْعَلَ",
                    "2sf": "تَفْعَلِي",
                    "1s": "أَفْعَلَ",
                    "3dm": "يَفْعَلا",
                    "3df": "تَفْعَلا",
                    "2d": "تَفْعَلا",
                    "3pm": "يَفْعَلُواْ",
                    "3pf": "يَفْعَلْنَ",
                    "2pm": "تَفْعَلُواْ",
                    "2pf": "تَفْعَلْنَ",
                    "1p": "نَفْعَلَ",
                },
                passive: {
                    "3sm": "يُفْعَلَ",
                    "3sf": "تُفْعَلَ",
                    "2sm": "تُفْعَلَ",
                    "2sf": "تُفْعَلِي",
                    "1s": "أُفْعَلَ",
                    "3dm": "يُفْعَلا",
                    "3df": "تُفْعَلا",
                    "2d": "تُفْعَلا",
                    "3pm": "يُفْعَلُواْ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُواْ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلَ",
                },
            },
            jussive: {
                active: {
                    "3sm": "يَفْعَلْ",
                    "3sf": "تَفْعَلْ",
                    "2sm": "تَفْعَلْ",
                    "2sf": "تَفْعَلِي",
                    "1s": "أَفْعَلْ",
                    "3dm": "يَفْعَلا",
                    "3df": "تَفْعَلا",
                    "2d": "تَفْعَلا",
                    "3pm": "يَفْعَلُواْ",
                    "3pf": "يَفْعَلْنَ",
                    "2pm": "تَفْعَلُواْ",
                    "2pf": "تَفْعَلْنَ",
                    "1p": "نَفْعَلْ",
                },
                passive:{
                    "3sm": "يُفْعَلْ",
                    "3sf": "تُفْعَلْ",
                    "2sm": "تُفْعَلْ",
                    "2sf": "تُفْعَلِي",
                    "1s": "أُفْعَلْ",
                    "3dm": "يُفْعَلا",
                    "3df": "تُفْعَلا",
                    "2d": "تُفْعَلا",
                    "3pm": "يُفْعَلُواْ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُواْ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلْ",
                },
            },
            imperative: {
                passive: {
                    "2sm": "اِفْعَلْ",
                    "2sf": "اِفْعَلِي",
                    "2d": "اِفْعَلا",
                    "2pm": "اِفْعَلُواْ",
                    "2pf": "اِفْعَلْنَ",
                }
            }
        }
    },
    {
        id: 1,
        form: "I",
        weakness: "sound",
        dummyRoots: ['ف', 'ع', 'ل'],
        rootIndices: [0, 2, 4],
        pastVowel: "a",
        presentVowel: "i",
        conjugations: {
            perfect: {
                active: {
                    "3sm": "فَعَلَ",
                    "3sf": "فَعَلَتْ",
                    "2sm": "فَعَلْتَ",
                    "2sf": "فَعَلْتِ",
                    "1s": "فَعَلْتُ",
                    "3dm": "فَعَلا",
                    "3df": "فَعَلَتا",
                    "2d": "فَعَلْتُما",
                    "3pm": "فَعَلُواْ",
                    "3pf": "فَعَلْنَ",
                    "2pm": "فَعَلْتُمْ",
                    "2pf": "فَعَلْتُنَّ",
                    "1p": "فَعَلْنا",
                },
                passive: {
                    "3sm": "فُعِلَ",
                    "3sf": "فُعِلَتْ",
                    "2sm": "فُعِلْتَ",
                    "2sf": "فُعِلْتِ",
                    "1s": "فُعِلْتُ",
                    "3dm": "فُعِلا",
                    "3df": "فُعِلَتا",
                    "2d": "فُعِلْتُما",
                    "3pm": "فُعِلُواْ",
                    "3pf": "فُعِلْنَ",
                    "2pm": "فُعِلْتُمْ",
                    "2pf": "فُعِلْتُنَّ",
                    "1p": "فُعِلْنا",
                },
            },
            indicative: {
                active: {
                    "3sm": "يَفْعِلُ",
                    "3sf": "تَفْعِلُ",
                    "2sm": "تَفْعِلُ",
                    "2sf": "تَفْعِلِينَ",
                    "1s": "أَفْعِلُ",
                    "3dm": "يَفْعِلانِ",
                    "3df": "تَفْعِلانِ",
                    "2d": "تَفْعِلانِ",
                    "3pm": "يَفْعِلُونَ",
                    "3pf": "يَفْعِلْنَ",
                    "2pm": "تَفْعِلُونَ",
                    "2pf": "تَفْعِلْنَ",
                    "1p": "نَفْعِلُ",
                },
                passive: {
                    "3sm": "يُفْعَلُ",
                    "3sf": "تُفْعَلُ",
                    "2sm": "تُفْعَلُ",
                    "2sf": "تُفْعَلِينَ",
                    "1s": "أُفْعَلُ",
                    "3dm": "يُفْعَلانِ",
                    "3df": "تُفْعَلانِ",
                    "2d": "تُفْعَلانِ",
                    "3pm": "يُفْعَلُونَ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُونَ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلُ",
                },
            },
            subjunctive: {
                active: {
                    "3sm": "يَفْعِلَ",
                    "3sf": "تَفْعِلَ",
                    "2sm": "تَفْعِلَ",
                    "2sf": "تَفْعِلِي",
                    "1s": "أَفْعِلَ",
                    "3dm": "يَفْعِلا",
                    "3df": "تَفْعِلا",
                    "2d": "تَفْعِلا",
                    "3pm": "يَفْعِلُواْ",
                    "3pf": "يَفْعِلْنَ",
                    "2pm": "تَفْعِلُواْ",
                    "2pf": "تَفْعِلْنَ",
                    "1p": "نَفْعِلَ",
                },
                passive: {
                    "3sm": "يُفْعَلَ",
                    "3sf": "تُفْعَلَ",
                    "2sm": "تُفْعَلَ",
                    "2sf": "تُفْعَلِي",
                    "1s": "أُفْعَلَ",
                    "3dm": "يُفْعَلا",
                    "3df": "تُفْعَلا",
                    "2d": "تُفْعَلا",
                    "3pm": "يُفْعَلُواْ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُواْ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلَ",
                },
            },
            jussive: {
                active: {
                    "3sm": "يَفْعِلْ",
                    "3sf": "تَفْعِلْ",
                    "2sm": "تَفْعِلْ",
                    "2sf": "تَفْعِلِي",
                    "1s": "أَفْعِلْ",
                    "3dm": "يَفْعِلا",
                    "3df": "تَفْعِلا",
                    "2d": "تَفْعِلا",
                    "3pm": "يَفْعِلُواْ",
                    "3pf": "يَفْعِلْنَ",
                    "2pm": "تَفْعِلُواْ",
                    "2pf": "تَفْعِلْنَ",
                    "1p": "نَفْعِلْ",
                },
                passive:{
                    "3sm": "يُفْعَلْ",
                    "3sf": "تُفْعَلْ",
                    "2sm": "تُفْعَلْ",
                    "2sf": "تُفْعَلِي",
                    "1s": "أُفْعَلْ",
                    "3dm": "يُفْعَلا",
                    "3df": "تُفْعَلا",
                    "2d": "تُفْعَلا",
                    "3pm": "يُفْعَلُواْ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُواْ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلْ",
                },
            },
            imperative: {
                passive: {
                    "2sm": "اِفْعِلْ",
                    "2sf": "اِفْعِلِي",
                    "2d": "اِفْعِلا",
                    "2pm": "اِفْعِلُواْ",
                    "2pf": "اِفْعِلْنَ",
                }
            }
        }
    },
    {
        id: 2,
        form: "I",
        weakness: "sound",
        dummyRoots: ['ف', 'ع', 'ل'],
        rootIndices: [0, 2, 4],
        pastVowel: "a",
        presentVowel: "u",
        conjugations: {
            perfect: {
                active: {
                    "3sm": "فَعَلَ",
                    "3sf": "فَعَلَتْ",
                    "2sm": "فَعَلْتَ",
                    "2sf": "فَعَلْتِ",
                    "1s": "فَعَلْتُ",
                    "3dm": "فَعَلا",
                    "3df": "فَعَلَتا",
                    "2d": "فَعَلْتُما",
                    "3pm": "فَعَلُواْ",
                    "3pf": "فَعَلْنَ",
                    "2pm": "فَعَلْتُمْ",
                    "2pf": "فَعَلْتُنَّ",
                    "1p": "فَعَلْنا",
                },
                passive: {
                    "3sm": "فُعِلَ",
                    "3sf": "فُعِلَتْ",
                    "2sm": "فُعِلْتَ",
                    "2sf": "فُعِلْتِ",
                    "1s": "فُعِلْتُ",
                    "3dm": "فُعِلا",
                    "3df": "فُعِلَتا",
                    "2d": "فُعِلْتُما",
                    "3pm": "فُعِلُواْ",
                    "3pf": "فُعِلْنَ",
                    "2pm": "فُعِلْتُمْ",
                    "2pf": "فُعِلْتُنَّ",
                    "1p": "فُعِلْنا",
                },
            },
            indicative: {
                active: {
                    "3sm": "يَفْعُلُ",
                    "3sf": "تَفْعُلُ",
                    "2sm": "تَفْعُلُ",
                    "2sf": "تَفْعُلِينَ",
                    "1s": "أَفْعُلُ",
                    "3dm": "يَفْعُلانِ",
                    "3df": "تَفْعُلانِ",
                    "2d": "تَفْعُلانِ",
                    "3pm": "يَفْعُلُونَ",
                    "3pf": "يَفْعُلْنَ",
                    "2pm": "تَفْعُلُونَ",
                    "2pf": "تَفْعُلْنَ",
                    "1p": "نَفْعُلُ",
                },
                passive: {
                    "3sm": "يُفْعَلُ",
                    "3sf": "تُفْعَلُ",
                    "2sm": "تُفْعَلُ",
                    "2sf": "تُفْعَلِينَ",
                    "1s": "أُفْعَلُ",
                    "3dm": "يُفْعَلانِ",
                    "3df": "تُفْعَلانِ",
                    "2d": "تُفْعَلانِ",
                    "3pm": "يُفْعَلُونَ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُونَ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلُ",
                },
            },
            subjunctive: {
                active: {
                    "3sm": "يَفْعُلَ",
                    "3sf": "تَفْعُلَ",
                    "2sm": "تَفْعُلَ",
                    "2sf": "تَفْعُلِي",
                    "1s": "أَفْعُلَ",
                    "3dm": "يَفْعُلا",
                    "3df": "تَفْعُلا",
                    "2d": "تَفْعُلا",
                    "3pm": "يَفْعُلُواْ",
                    "3pf": "يَفْعُلْنَ",
                    "2pm": "تَفْعُلُواْ",
                    "2pf": "تَفْعُلْنَ",
                    "1p": "نَفْعُلَ",
                },
                passive: {
                    "3sm": "يُفْعَلَ",
                    "3sf": "تُفْعَلَ",
                    "2sm": "تُفْعَلَ",
                    "2sf": "تُفْعَلِي",
                    "1s": "أُفْعَلَ",
                    "3dm": "يُفْعَلا",
                    "3df": "تُفْعَلا",
                    "2d": "تُفْعَلا",
                    "3pm": "يُفْعَلُواْ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُواْ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلَ",
                },
            },
            jussive: {
                active: {
                    "3sm": "يَفْعُلْ",
                    "3sf": "تَفْعُلْ",
                    "2sm": "تَفْعُلْ",
                    "2sf": "تَفْعُلِي",
                    "1s": "أَفْعُلْ",
                    "3dm": "يَفْعُلا",
                    "3df": "تَفْعُلا",
                    "2d": "تَفْعُلا",
                    "3pm": "يَفْعُلُواْ",
                    "3pf": "يَفْعُلْنَ",
                    "2pm": "تَفْعُلُواْ",
                    "2pf": "تَفْعُلْنَ",
                    "1p": "نَفْعُلْ",
                },
                passive:{
                    "3sm": "يُفْعَلْ",
                    "3sf": "تُفْعَلْ",
                    "2sm": "تُفْعَلْ",
                    "2sf": "تُفْعَلِي",
                    "1s": "أُفْعَلْ",
                    "3dm": "يُفْعَلا",
                    "3df": "تُفْعَلا",
                    "2d": "تُفْعَلا",
                    "3pm": "يُفْعَلُواْ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُواْ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلْ",
                },
            },
            imperative: {
                passive: {
                    "2sm": "اُفْعُلْ",
                    "2sf": "اُفْعُلِي",
                    "2d": "اُفْعُلا",
                    "2pm": "اُفْعُلُواْ",
                    "2pf": "اُفْعُلْنَ",
                }
            }
        }
    },
    {
        id: 3,
        form: "I",
        weakness: "sound",
        dummyRoots: ['ف', 'ع', 'ل'],
        rootIndices: [0, 2, 4],
        pastVowel: "i",
        presentVowel: "a",
        conjugations: {
            perfect: {
                active: {
                    "3sm": "فَعِلَ",
                    "3sf": "فَعِلَتْ",
                    "2sm": "فَعِلْتَ",
                    "2sf": "فَعِلْتِ",
                    "1s": "فَعِلْتُ",
                    "3dm": "فَعِلا",
                    "3df": "فَعِلَتا",
                    "2d": "فَعِلْتُما",
                    "3pm": "فَعِلُواْ",
                    "3pf": "فَعِلْنَ",
                    "2pm": "فَعِلْتُمْ",
                    "2pf": "فَعِلْتُنَّ",
                    "1p": "فَعِلْنا",
                },
                passive: {
                    "3sm": "فُعِلَ",
                    "3sf": "فُعِلَتْ",
                    "2sm": "فُعِلْتَ",
                    "2sf": "فُعِلْتِ",
                    "1s": "فُعِلْتُ",
                    "3dm": "فُعِلا",
                    "3df": "فُعِلَتا",
                    "2d": "فُعِلْتُما",
                    "3pm": "فُعِلُواْ",
                    "3pf": "فُعِلْنَ",
                    "2pm": "فُعِلْتُمْ",
                    "2pf": "فُعِلْتُنَّ",
                    "1p": "فُعِلْنا",
                },
            },
            indicative: {
                active: {
                    "3sm": "يَفْعَلُ",
                    "3sf": "تَفْعَلُ",
                    "2sm": "تَفْعَلُ",
                    "2sf": "تَفْعَلِينَ",
                    "1s": "أَفْعَلُ",
                    "3dm": "يَفْعَلانِ",
                    "3df": "تَفْعَلانِ",
                    "2d": "تَفْعَلانِ",
                    "3pm": "يَفْعَلُونَ",
                    "3pf": "يَفْعَلْنَ",
                    "2pm": "تَفْعَلُونَ",
                    "2pf": "تَفْعَلْنَ",
                    "1p": "نَفْعَلُ",
                },
                passive: {
                    "3sm": "يُفْعَلُ",
                    "3sf": "تُفْعَلُ",
                    "2sm": "تُفْعَلُ",
                    "2sf": "تُفْعَلِينَ",
                    "1s": "أُفْعَلُ",
                    "3dm": "يُفْعَلانِ",
                    "3df": "تُفْعَلانِ",
                    "2d": "تُفْعَلانِ",
                    "3pm": "يُفْعَلُونَ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُونَ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلُ",
                },
            },
            subjunctive: {
                active: {
                    "3sm": "يَفْعَلَ",
                    "3sf": "تَفْعَلَ",
                    "2sm": "تَفْعَلَ",
                    "2sf": "تَفْعَلِي",
                    "1s": "أَفْعَلَ",
                    "3dm": "يَفْعَلا",
                    "3df": "تَفْعَلا",
                    "2d": "تَفْعَلا",
                    "3pm": "يَفْعَلُواْ",
                    "3pf": "يَفْعَلْنَ",
                    "2pm": "تَفْعَلُواْ",
                    "2pf": "تَفْعَلْنَ",
                    "1p": "نَفْعَلَ",
                },
                passive: {
                    "3sm": "يُفْعَلَ",
                    "3sf": "تُفْعَلَ",
                    "2sm": "تُفْعَلَ",
                    "2sf": "تُفْعَلِي",
                    "1s": "أُفْعَلَ",
                    "3dm": "يُفْعَلا",
                    "3df": "تُفْعَلا",
                    "2d": "تُفْعَلا",
                    "3pm": "يُفْعَلُواْ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُواْ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلَ",
                },
            },
            jussive: {
                active: {
                    "3sm": "يَفْعَلْ",
                    "3sf": "تَفْعَلْ",
                    "2sm": "تَفْعَلْ",
                    "2sf": "تَفْعَلِي",
                    "1s": "أَفْعَلْ",
                    "3dm": "يَفْعَلا",
                    "3df": "تَفْعَلا",
                    "2d": "تَفْعَلا",
                    "3pm": "يَفْعَلُواْ",
                    "3pf": "يَفْعَلْنَ",
                    "2pm": "تَفْعَلُواْ",
                    "2pf": "تَفْعَلْنَ",
                    "1p": "نَفْعَلْ",
                },
                passive:{
                    "3sm": "يُفْعَلْ",
                    "3sf": "تُفْعَلْ",
                    "2sm": "تُفْعَلْ",
                    "2sf": "تُفْعَلِي",
                    "1s": "أُفْعَلْ",
                    "3dm": "يُفْعَلا",
                    "3df": "تُفْعَلا",
                    "2d": "تُفْعَلا",
                    "3pm": "يُفْعَلُواْ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُواْ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلْ",
                },
            },
            imperative: {
                passive: {
                    "2sm": "اِفْعَلْ",
                    "2sf": "اِفْعَلِي",
                    "2d": "اِفْعَلا",
                    "2pm": "اِفْعَلُواْ",
                    "2pf": "اِفْعَلْنَ",
                }
            }
        }
    },
    {
        id: 4,
        form: "I",
        weakness: "sound",
        dummyRoots: ['ف', 'ع', 'ل'],
        rootIndices: [0, 2, 4],
        pastVowel: "i",
        presentVowel: "i",
        conjugations: {
            perfect: {
                active: {
                    "3sm": "فَعِلَ",
                    "3sf": "فَعِلَتْ",
                    "2sm": "فَعِلْتَ",
                    "2sf": "فَعِلْتِ",
                    "1s": "فَعِلْتُ",
                    "3dm": "فَعِلا",
                    "3df": "فَعِلَتا",
                    "2d": "فَعِلْتُما",
                    "3pm": "فَعِلُواْ",
                    "3pf": "فَعِلْنَ",
                    "2pm": "فَعِلْتُمْ",
                    "2pf": "فَعِلْتُنَّ",
                    "1p": "فَعِلْنا",
                },
                passive: {
                    "3sm": "فُعِلَ",
                    "3sf": "فُعِلَتْ",
                    "2sm": "فُعِلْتَ",
                    "2sf": "فُعِلْتِ",
                    "1s": "فُعِلْتُ",
                    "3dm": "فُعِلا",
                    "3df": "فُعِلَتا",
                    "2d": "فُعِلْتُما",
                    "3pm": "فُعِلُواْ",
                    "3pf": "فُعِلْنَ",
                    "2pm": "فُعِلْتُمْ",
                    "2pf": "فُعِلْتُنَّ",
                    "1p": "فُعِلْنا",
                },
            },
            indicative: {
                active: {
                    "3sm": "يَفْعِلُ",
                    "3sf": "تَفْعِلُ",
                    "2sm": "تَفْعِلُ",
                    "2sf": "تَفْعِلِينَ",
                    "1s": "أَفْعِلُ",
                    "3dm": "يَفْعِلانِ",
                    "3df": "تَفْعِلانِ",
                    "2d": "تَفْعِلانِ",
                    "3pm": "يَفْعِلُونَ",
                    "3pf": "يَفْعِلْنَ",
                    "2pm": "تَفْعِلُونَ",
                    "2pf": "تَفْعِلْنَ",
                    "1p": "نَفْعِلُ",
                },
                passive: {
                    "3sm": "يُفْعَلُ",
                    "3sf": "تُفْعَلُ",
                    "2sm": "تُفْعَلُ",
                    "2sf": "تُفْعَلِينَ",
                    "1s": "أُفْعَلُ",
                    "3dm": "يُفْعَلانِ",
                    "3df": "تُفْعَلانِ",
                    "2d": "تُفْعَلانِ",
                    "3pm": "يُفْعَلُونَ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُونَ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلُ",
                },
            },
            subjunctive: {
                active: {
                    "3sm": "يَفْعِلَ",
                    "3sf": "تَفْعِلَ",
                    "2sm": "تَفْعِلَ",
                    "2sf": "تَفْعِلِي",
                    "1s": "أَفْعِلَ",
                    "3dm": "يَفْعِلا",
                    "3df": "تَفْعِلا",
                    "2d": "تَفْعِلا",
                    "3pm": "يَفْعِلُواْ",
                    "3pf": "يَفْعِلْنَ",
                    "2pm": "تَفْعِلُواْ",
                    "2pf": "تَفْعِلْنَ",
                    "1p": "نَفْعِلَ",
                },
                passive: {
                    "3sm": "يُفْعَلَ",
                    "3sf": "تُفْعَلَ",
                    "2sm": "تُفْعَلَ",
                    "2sf": "تُفْعَلِي",
                    "1s": "أُفْعَلَ",
                    "3dm": "يُفْعَلا",
                    "3df": "تُفْعَلا",
                    "2d": "تُفْعَلا",
                    "3pm": "يُفْعَلُواْ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُواْ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلَ",
                },
            },
            jussive: {
                active: {
                    "3sm": "يَفْعِلْ",
                    "3sf": "تَفْعِلْ",
                    "2sm": "تَفْعِلْ",
                    "2sf": "تَفْعِلِي",
                    "1s": "أَفْعِلْ",
                    "3dm": "يَفْعِلا",
                    "3df": "تَفْعِلا",
                    "2d": "تَفْعِلا",
                    "3pm": "يَفْعِلُواْ",
                    "3pf": "يَفْعِلْنَ",
                    "2pm": "تَفْعِلُواْ",
                    "2pf": "تَفْعِلْنَ",
                    "1p": "نَفْعِلْ",
                },
                passive:{
                    "3sm": "يُفْعَلْ",
                    "3sf": "تُفْعَلْ",
                    "2sm": "تُفْعَلْ",
                    "2sf": "تُفْعَلِي",
                    "1s": "أُفْعَلْ",
                    "3dm": "يُفْعَلا",
                    "3df": "تُفْعَلا",
                    "2d": "تُفْعَلا",
                    "3pm": "يُفْعَلُواْ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُواْ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلْ",
                },
            },
            imperative: {
                passive: {
                    "2sm": "اِفْعِلْ",
                    "2sf": "اِفْعِلِي",
                    "2d": "اِفْعِلا",
                    "2pm": "اِفْعِلُواْ",
                    "2pf": "اِفْعِلْنَ",
                }
            }
        }
    },
    {
        id: 5,
        form: "I",
        weakness: "sound",
        dummyRoots: ['ف', 'ع', 'ل'],
        rootIndices: [0, 2, 4],
        pastVowel: "u",
        presentVowel: "u",
        conjugations: {
            perfect: {
                active: {
                    "3sm": "فَعُلَ",
                    "3sf": "فَعُلَتْ",
                    "2sm": "فَعُلْتَ",
                    "2sf": "فَعُلْتِ",
                    "1s": "فَعُلْتُ",
                    "3dm": "فَعُلا",
                    "3df": "فَعُلَتا",
                    "2d": "فَعُلْتُما",
                    "3pm": "فَعُلُواْ",
                    "3pf": "فَعُلْنَ",
                    "2pm": "فَعُلْتُمْ",
                    "2pf": "فَعُلْتُنَّ",
                    "1p": "فَعُلْنا",
                },
                passive: {
                    "3sm": "فُعِلَ",
                    "3sf": "فُعِلَتْ",
                    "2sm": "فُعِلْتَ",
                    "2sf": "فُعِلْتِ",
                    "1s": "فُعِلْتُ",
                    "3dm": "فُعِلَا",
                    "3df": "فُعِلَتا",
                    "2d": "فُعِلْتُما",
                    "3pm": "فُعِلُواْ",
                    "3pf": "فُعِلْنَ",
                    "2pm": "فُعِلْتُمْ",
                    "2pf": "فُعِلْتُنَّ",
                    "1p": "فُعِلْنا",
                },
            },
            indicative: {
                active: {
                    "3sm": "يَفْعُلُ",
                    "3sf": "تَفْعُلُ",
                    "2sm": "تَفْعُلُ",
                    "2sf": "تَفْعُلِينَ",
                    "1s": "أَفْعُلُ",
                    "3dm": "يَفْعُلانِ",
                    "3df": "تَفْعُلانِ",
                    "2d": "تَفْعُلانِ",
                    "3pm": "يَفْعُلُونَ",
                    "3pf": "يَفْعُلْنَ",
                    "2pm": "تَفْعُلُونَ",
                    "2pf": "تَفْعُلْنَ",
                    "1p": "نَفْعُلُ",
                },
                passive: {
                    "3sm": "يُفْعَلُ",
                    "3sf": "تُفْعَلُ",
                    "2sm": "تُفْعَلُ",
                    "2sf": "تُفْعَلِينَ",
                    "1s": "أُفْعَلُ",
                    "3dm": "يُفْعَلانِ",
                    "3df": "تُفْعَلانِ",
                    "2d": "تُفْعَلانِ",
                    "3pm": "يُفْعَلُونَ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُونَ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلُ",
                },
            },
            subjunctive: {
                active: {
                    "3sm": "يَفْعُلَ",
                    "3sf": "تَفْعُلَ",
                    "2sm": "تَفْعُلَ",
                    "2sf": "تَفْعُلِي",
                    "1s": "أَفْعُلَ",
                    "3dm": "يَفْعُلا",
                    "3df": "تَفْعُلا",
                    "2d": "تَفْعُلا",
                    "3pm": "يَفْعُلُواْ",
                    "3pf": "يَفْعُلْنَ",
                    "2pm": "تَفْعُلُواْ",
                    "2pf": "تَفْعُلْنَ",
                    "1p": "نَفْعُلَ",
                },
                passive: {
                    "3sm": "يُفْعَلَ",
                    "3sf": "تُفْعَلَ",
                    "2sm": "تُفْعَلَ",
                    "2sf": "تُفْعَلِي",
                    "1s": "أُفْعَلَ",
                    "3dm": "يُفْعَلا",
                    "3df": "تُفْعَلا",
                    "2d": "تُفْعَلا",
                    "3pm": "يُفْعَلُواْ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُواْ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلَ",
                },
            },
            jussive: {
                active: {
                    "3sm": "يَفْعُلْ",
                    "3sf": "تَفْعُلْ",
                    "2sm": "تَفْعُلْ",
                    "2sf": "تَفْعُلِي",
                    "1s": "أَفْعُلْ",
                    "3dm": "يَفْعُلا",
                    "3df": "تَفْعُلا",
                    "2d": "تَفْعُلا",
                    "3pm": "يَفْعُلُواْ",
                    "3pf": "يَفْعُلْنَ",
                    "2pm": "تَفْعُلُواْ",
                    "2pf": "تَفْعُلْنَ",
                    "1p": "نَفْعُلْ",
                },
                passive:{
                    "3sm": "يُفْعَلْ",
                    "3sf": "تُفْعَلْ",
                    "2sm": "تُفْعَلْ",
                    "2sf": "تُفْعَلِي",
                    "1s": "أُفْعَلْ",
                    "3dm": "يُفْعَلا",
                    "3df": "تُفْعَلا",
                    "2d": "تُفْعَلا",
                    "3pm": "يُفْعَلُواْ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُواْ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلْ",
                },
            },
            imperative: {
                passive: {
                    "2sm": "اُفْعُلْ",
                    "2sf": "اُفْعُلِي",
                    "2d": "اُفْعُلا",
                    "2pm": "اُفْعُلُواْ",
                    "2pf": "اُفْعُلْنَ",
                }
            }
        }
    },
        {
        id: 5,
        form: "I",
        weakness: "sound",
        dummyRoots: ['ف', 'ع', 'ل'],
        rootIndices: [0, 2, 4],
        pastVowel: "u",
        presentVowel: "u",
        conjugations: {
            perfect: {
                active: {
                    "3sm": "فَعُلَ",
                    "3sf": "فَعُلَتْ",
                    "2sm": "فَعُلْتَ",
                    "2sf": "فَعُلْتِ",
                    "1s": "فَعُلْتُ",
                    "3dm": "فَعُلا",
                    "3df": "فَعُلَتا",
                    "2d": "فَعُلْتُما",
                    "3pm": "فَعُلُواْ",
                    "3pf": "فَعُلْنَ",
                    "2pm": "فَعُلْتُمْ",
                    "2pf": "فَعُلْتُنّ",
                    "1p": "فَعُلْنا",
                },
                passive: {
                    "3sm": "فُعِلَ",
                    "3sf": "فُعِلَتْ",
                    "2sm": "فُعِلْتَ",
                    "2sf": "فُعِلْتِ",
                    "1s": "فُعِلْتُ",
                    "3dm": "فُعِلا",
                    "3df": "فُعِلَتا",
                    "2d": "فُعِلْتُما",
                    "3pm": "فُعِلُواْ",
                    "3pf": "فُعِلْنَ",
                    "2pm": "فُعِلْتُمْ",
                    "2pf": "فُعِلْتُنَّ",
                    "1p": "فُعِلْنا",
                },
            },
            indicative: {
                active: {
                    "3sm": "يَفْعُلُ",
                    "3sf": "تَفْعُلُ",
                    "2sm": "تَفْعُلُ",
                    "2sf": "تَفْعُلِينَ",
                    "1s": "أَفْعُلُ",
                    "3dm": "يَفْعُلانِ",
                    "3df": "تَفْعُلانِ",
                    "2d": "تَفْعُلانِ",
                    "3pm": "يَفْعُلُونَ",
                    "3pf": "يَفْعُلْنَ",
                    "2pm": "تَفْعُلُونَ",
                    "2pf": "تَفْعُلْنَ",
                    "1p": "نَفْعُلُ",
                },
                passive: {
                    "3sm": "يُفْعَلُ",
                    "3sf": "تُفْعَلُ",
                    "2sm": "تُفْعَلُ",
                    "2sf": "تُفْعَلِينَ",
                    "1s": "أُفْعَلُ",
                    "3dm": "يُفْعَلانِ",
                    "3df": "تُفْعَلانِ",
                    "2d": "تُفْعَلانِ",
                    "3pm": "يُفْعَلُونَ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُونَ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلُ",
                },
            },
            subjunctive: {
                active: {
                    "3sm": "يَفْعُلَ",
                    "3sf": "تَفْعُلَ",
                    "2sm": "تَفْعُلَ",
                    "2sf": "تَفْعُلِي",
                    "1s": "أَفْعُلَ",
                    "3dm": "يَفْعُلا",
                    "3df": "تَفْعُلا",
                    "2d": "تَفْعُلا",
                    "3pm": "يَفْعُلُواْ",
                    "3pf": "يَفْعُلْنَ",
                    "2pm": "تَفْعُلُواْ",
                    "2pf": "تَفْعُلْنَ",
                    "1p": "نَفْعُلَ",
                },
                passive: {
                    "3sm": "يُفْعَلَ",
                    "3sf": "تُفْعَلَ",
                    "2sm": "تُفْعَلَ",
                    "2sf": "تُفْعَلِي",
                    "1s": "أُفْعَلَ",
                    "3dm": "يُفْعَلا",
                    "3df": "تُفْعَلا",
                    "2d": "تُفْعَلا",
                    "3pm": "يُفْعَلُواْ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُواْ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلَ",
                },
            },
            jussive: {
                active: {
                    "3sm": "يَفْعُلْ",
                    "3sf": "تَفْعُلْ",
                    "2sm": "تَفْعُلْ",
                    "2sf": "تَفْعُلِي",
                    "1s": "أَفْعُلْ",
                    "3dm": "يَفْعُلا",
                    "3df": "تَفْعُلا",
                    "2d": "تَفْعُلا",
                    "3pm": "يَفْعُلُواْ",
                    "3pf": "يَفْعُلْنَ",
                    "2pm": "تَفْعُلُواْ",
                    "2pf": "تَفْعُلْنَ",
                    "1p": "نَفْعُلْ",
                },
                passive:{
                    "3sm": "يُفْعَلْ",
                    "3sf": "تُفْعَلْ",
                    "2sm": "تُفْعَلْ",
                    "2sf": "تُفْعَلِي",
                    "1s": "أُفْعَلْ",
                    "3dm": "يُفْعَلا",
                    "3df": "تُفْعَلا",
                    "2d": "تُفْعَلا",
                    "3pm": "يُفْعَلُواْ",
                    "3pf": "يُفْعَلْنَ",
                    "2pm": "تُفْعَلُواْ",
                    "2pf": "تُفْعَلْنَ",
                    "1p": "نُفْعَلْ",
                },
            },
            imperative: {
                passive: {
                    "2sm": "اُفْعُلْ",
                    "2sf": "اُفْعُلِي",
                    "2d": "اُفْعُلا",
                    "2pm": "اُفْعُلُواْ",
                    "2pf": "اُفْعُلْنَ",
                }
            }
        }
    },
    {
        id: 6,
        form: "II",
        weakness: "sound",
        dummyRoots: ['ف', 'ع', 'ل'],
        rootIndices: [0, 2, 5],
        conjugations: {
            perfect: {
                active: {
                    "3sm": "فَعَّلَ",
                    "3sf": "فَعَّلَتْ",
                    "2sm": "فَعَّلْتَ",
                    "2sf": "فَعَّلْتِ",
                    "1s": "فَعَّلْتُ",
                    "3dm": "فَعَّلا",
                    "3df": "فَعَّلَتا",
                    "2d": "فَعَّلْتُما",
                    "3pm": "فَعَّلُواْ",
                    "3pf": "فَعَّلْنَ",
                    "2pm": "فَعَّلْتُمْ",
                    "2pf": "فَعَّلْتُنَّ",
                    "1p": "فَعَّلْنا",
                },
                passive: {
                    "3sm": "فُعِّلَ",
                    "3sf": "فُعِّلَتْ",
                    "2sm": "فُعِّلْتَ",
                    "2sf": "فُعِّلْتِ",
                    "1s": "فُعِّلْتُ",
                    "3dm": "فُعِّلا",
                    "3df": "فُعِّلَتا",
                    "2d": "فُعِّلْتُما",
                    "3pm": "فُعِّلُواْ",
                    "3pf": "فُعِّلْنَ",
                    "2pm": "فُعِّلْتُمْ",
                    "2pf": "فُعِّلْتُنَّ",
                    "1p": "فُعِّلْنا",
                },
            },
            indicative: {
                active: {
                    "3sm": "يُفَعِّلُ",
                    "3sf": "تُفَعِّلُ",
                    "2sm": "تُفَعِّلُ",
                    "2sf": "تُفَعِّلِينَ",
                    "1s": "أُفَعِّلُ",
                    "3dm": "يُفَعِّلانِ",
                    "3df": "تُفَعِّلانِ",
                    "2d": "تُفَعِّلانِ",
                    "3pm": "يُفَعِّلُونَ",
                    "3pf": "يُفَعِّلْنَ",
                    "2pm": "تُفَعِّلُونَ",
                    "2pf": "تُفَعِّلْنَ",
                    "1p": "نُفَعِّلُ",
                },
                passive: {
                    "3sm": "يُفَعَّلُ",
                    "3sf": "تُفَعَّلُ",
                    "2sm": "تُفَعَّلُ",
                    "2sf": "تُفَعَّلِينَ",
                    "1s": "أُفَعَّلُ",
                    "3dm": "يُفَعَّلانِ",
                    "3df": "تُفَعَّلانِ",
                    "2d": "تُفَعَّلانِ",
                    "3pm": "يُفَعَّلُونَ",
                    "3pf": "يُفَعَّلْنَ",
                    "2pm": "تُفَعَّلُونَ",
                    "2pf": "تُفَعَّلْنَ",
                    "1p": "نُفَعَّلُ",
                },
            },
            subjunctive: {
                active: {
                    "3sm": "يُفَعِّلَ",
                    "3sf": "تُفَعِّلَ",
                    "2sm": "تُفَعِّلَ",
                    "2sf": "تُفَعِّلِي",
                    "1s": "أُفَعِّلَ",
                    "3dm": "يُفَعِّلا",
                    "3df": "تُفَعِّلا",
                    "2d": "تُفَعِّلا",
                    "3pm": "يُفَعِّلُواْ",
                    "3pf": "يُفَعِّلْنَ",
                    "2pm": "تُفَعِّلُواْ",
                    "2pf": "تُفَعِّلْنَ",
                    "1p": "نُفَعِّلَ",
                },
                passive: {
                    "3sm": "يُفَعَّلَ",
                    "3sf": "تُفَعَّلَ",
                    "2sm": "تُفَعَّلَ",
                    "2sf": "تُفَعَّلِي",
                    "1s": "أُفَعَّلَ",
                    "3dm": "يُفَعَّلا",
                    "3df": "تُفَعَّلا",
                    "2d": "تُفَعَّلا",
                    "3pm": "يُفَعَّلُواْ",
                    "3pf": "يُفَعَّلْنَ",
                    "2pm": "تُفَعَّلُواْ",
                    "2pf": "تُفَعَّلْنَ",
                    "1p": "نُفَعَّلَ",
                },
            },
            jussive: {
                active: {
                    "3sm": "يُفَعِّلْ",
                    "3sf": "تُفَعِّلْ",
                    "2sm": "تُفَعِّلْ",
                    "2sf": "تُفَعِّلِي",
                    "1s": "أُفَعِّلْ",
                    "3dm": "يُفَعِّلا",
                    "3df": "تُفَعِّلا",
                    "2d": "تُفَعِّلا",
                    "3pm": "يُفَعِّلُواْ",
                    "3pf": "يُفَعِّلْنَ",
                    "2pm": "تُفَعِّلُواْ",
                    "2pf": "تُفَعِّلْنَ",
                    "1p": "نُفَعِّلْ",
                },
                passive:{
                    "3sm": "يُفَعَّلْ",
                    "3sf": "تُفَعَّلْ",
                    "2sm": "تُفَعَّلْ",
                    "2sf": "تُفَعَّلِي",
                    "1s": "أُفَعَّلْ",
                    "3dm": "يُفَعَّلا",
                    "3df": "تُفَعَّلا",
                    "2d": "تُفَعَّلا",
                    "3pm": "يُفَعَّلُواْ",
                    "3pf": "يُفَعَّلْنَ",
                    "2pm": "تُفَعَّلُواْ",
                    "2pf": "تُفَعَّلْنَ",
                    "1p": "نُفَعَّلْ",
                },
            },
            imperative: {
                passive: {
                    "2sm": "فَعِّلْ",
                    "2sf": "فَعِّلِي",
                    "2d": "فَعِّلا",
                    "2pm": "فَعِّلُواْ",
                    "2pf": "فَعِّلْنَ",
                }
            }
        }
    },
    {
        id: 7,
        form: "III",
        weakness: "sound",
        dummyRoots: ['ف', 'ع', 'ل'],
        rootIndices: [0, 2, 4],
        pastVowel: "a",
        presentVowel: "a",
        conjugations: {
            perfect: {
                active: {
                    "3sm": "فاعَلَ",
                    "3sf": "فاعَلَتْ",
                    "2sm": "فاعَلْتَ",
                    "2sf": "فاعَلْتِ",
                    "1s": "فاعَلْتُ",
                    "3dm": "فاعَلا",
                    "3df": "فاعَلَتا",
                    "2d": "فاعَلْتُما",
                    "3pm": "فاعَلُواْ",
                    "3pf": "فاعَلْنَ",
                    "2pm": "فاعَلْتُمْ",
                    "2pf": "فاعَلْتُنَّ",
                    "1p": "فاعَلْنا",
                },
                passive: {
                    "3sm": "فُوعِلَ",
                    "3sf": "فُوعِلَتْ",
                    "2sm": "فُوعِلْتَ",
                    "2sf": "فُوعِلْتِ",
                    "1s": "فُوعِلْتُ",
                    "3dm": "فُوعِلا",
                    "3df": "فُوعِلَتا",
                    "2d": "فُوعِلْتُما",
                    "3pm": "فُوعِلُواْ",
                    "3pf": "فُوعِلْنَ",
                    "2pm": "فُوعِلْتُمْ",
                    "2pf": "فُوعِلْتُنَّ",
                    "1p": "فُوعِلْنا",
                },
            },
            indicative: {
                active: {
                    "3sm": "يُفاعِلُ",
                    "3sf": "تُفاعِلُ",
                    "2sm": "تُفاعِلُ",
                    "2sf": "تُفاعِلِينَ",
                    "1s": "أُفاعِلُ",
                    "3dm": "يُفاعِلانِ",
                    "3df": "تُفاعِلانِ",
                    "2d": "تُفاعِلانِ",
                    "3pm": "يُفاعِلُونَ",
                    "3pf": "يُفاعِلْنَ",
                    "2pm": "تُفاعِلُونَ",
                    "2pf": "تُفاعِلْنَ",
                    "1p": "نُفاعِلُ",
                },
                passive: {
                    "3sm": "يُفاعِلُ",
                    "3sf": "تُفاعِلُ",
                    "2sm": "تُفاعِلُ",
                    "2sf": "تُفاعِلِينَ",
                    "1s": "أُفاعِلُ",
                    "3dm": "يُفاعِلانِ",
                    "3df": "تُفاعِلانِ",
                    "2d": "تُفاعِلانِ",
                    "3pm": "يُفاعِلُونَ",
                    "3pf": "يُفاعِلْنَ",
                    "2pm": "تُفاعِلُونَ",
                    "2pf": "تُفاعِلْنَ",
                    "1p": "نُفاعِلُ",
                },
            },
            subjunctive: {
                active: {
                    "3sm": "يُفاعِلَ",
                    "3sf": "تُفاعِلَ",
                    "2sm": "تُفاعِلَ",
                    "2sf": "تُفاعِلِي",
                    "1s": "أُفاعِلَ",
                    "3dm": "يُفاعِلا",
                    "3df": "تُفاعِلا",
                    "2d": "تُفاعِلا",
                    "3pm": "يُفاعِلُواْ",
                    "3pf": "يُفاعِلْنَ",
                    "2pm": "تُفاعِلُواْ",
                    "2pf": "تُفاعِلْنَ",
                    "1p": "نُفاعِلَ",
                },
                passive: {
                    "3sm": "يُفاعَلَ",
                    "3sf": "تُفاعَلَ",
                    "2sm": "تُفاعَلَ",
                    "2sf": "تُفاعَلِي",
                    "1s": "أُفاعَلَ",
                    "3dm": "يُفاعَلا",
                    "3df": "تُفاعَلا",
                    "2d": "تُفاعَلا",
                    "3pm": "يُفاعَلُواْ",
                    "3pf": "يُفاعَلْنَ",
                    "2pm": "تُفاعَلُواْ",
                    "2pf": "تُفاعَلْنَ",
                    "1p": "نُفاعَلَ",
                },
            },
            jussive: {
                active: {
                    "3sm": "يُفاعِلْ",
                    "3sf": "تُفاعِلْ",
                    "2sm": "تُفاعِلْ",
                    "2sf": "تُفاعِلِي",
                    "1s": "أُفاعِلْ",
                    "3dm": "يُفاعِلا",
                    "3df": "تُفاعِلا",
                    "2d": "تُفاعِلا",
                    "3pm": "يُفاعِلُواْ",
                    "3pf": "يُفاعِلْنَ",
                    "2pm": "تُفاعِلُواْ",
                    "2pf": "تُفاعِلْنَ",
                    "1p": "نُفاعِلْ",
                },
                passive:{
                    "3sm": "يُفاعَلْ",
                    "3sf": "تُفاعَلْ",
                    "2sm": "تُفاعَلْ",
                    "2sf": "تُفاعَلِي",
                    "1s": "أُفاعَلْ",
                    "3dm": "يُفاعَلا",
                    "3df": "تُفاعَلا",
                    "2d": "تُفاعَلا",
                    "3pm": "يُفاعَلُواْ",
                    "3pf": "يُفاعَلْنَ",
                    "2pm": "تُفاعَلُواْ",
                    "2pf": "تُفاعَلْنَ",
                    "1p": "نُفاعَلْ",
                },
            },
            imperative: {
                passive: {
                    "2sm": "فاعِلْ",
                    "2sf": "فاعِلِي",
                    "2d": "فاعِلا",
                    "2pm": "فاعِلُواْ",
                    "2pf": "فاعِلْنَ",
                }
            }
        }
    },
]