export const verbBank = [
    {
        id: 0,
        infinitiveArabic: "فَعَلَ",
        infinitiveEnglish: "to do",
        roots: ["ف", "ع", "ل"],
        form: "I",
        weakness: "sound",
        verbRarity: "common",
        verbQuranic: "quranic",
        pastVowel: "a",
        presentVowel: "a",
    },
    {
        id: 1,
        infinitiveArabic: "فَحَصَ",
        infinitiveEnglish: "to inspect",
        roots: ["ف", "ح", "ص"],
        form: "I",
        weakness: "sound",
        verbRarity: "common",
        verbQuranic: "non-quranic",
        pastVowel: "a",
        presentVowel: "a",
    },
    {
        id: 2,
        infinitiveArabic: "بَدَلَ",
        infinitiveEnglish: "to change, exchange",
        roots: ["ب", "د", "ل"],
        form: "I",
        weakness: "sound",
        verbRarity: "common",
        verbQuranic: "quranic",
        pastVowel: "a",
        presentVowel: "u",
    },
    {
        id: 3,
        infinitiveArabic: "ذَكَّرَ",
        infinitiveEnglish: "to remind",
        roots: ["ذ", "ك", "ر"],
        form: "II",
        weakness: "sound",
        verbRarity: "common",
        verbQuranic: "quranic",
    },
    {
        id: 4,
        infinitiveArabic: "بَحَثَ",
        infinitiveEnglish: "to investigate, examine, search for",
        roots: ["ب", "ح", "ث"],
        form: "I",
        verbType: "sound",
        verbRarity: "common",
        quranic: true,
        pastVowel: "a",
        presentVowel: "a",
    },
    {
        id: 5,
        infinitiveArabic: "بَذَلَ",
        infinitiveEnglish: "to give generously, squander",
        roots: ["ب", "ذ", "ل"],
        form: "I",
        weakness: "sound",
        verbRarity: "common",
        verbQuranic: "non-quranic",
        pastVowel: "a",
        presentVowel: "u",
    },
    {
        id: 6,
        infinitiveArabic: "بَسَطَ",
        infinitiveEnglish: "to spread out; to please",
        roots: ["ب", "س", "ط"],
        form: "I",
        weakness: "sound",
        verbRarity: "common",
        verbQuranic: "quranic",
        pastVowel: "a",
        presentVowel: "u",
    },
    {
        id: 7,
        infinitiveArabic: "بَشَّرَ",
        infinitiveEnglish: "to give good news about",
        roots: ["ب", "ش", "ر"],
        form: "II",
        weakness: "sound",
        verbRarity: "common",
        verbQuranic: "quranic",
    },
    {
        id: 8,
        infinitiveArabic: "بَصَّرَ",
        infinitiveEnglish: "to open the eyes, enlighten anyone",
        roots: ["ب", "ص", "ر"],
        form: "II",
        weakness: "sound",
        verbRarity: "rare",
        verbQuranic: "non-quranic",
    },
    {
        id: 9,
        infinitiveArabic: "بَصَقَ",
        infinitiveEnglish: "to spit",
        roots: ["ب", "ص", "ق"],
        form: "I",
        weakness: "sound",
        verbRarity: "rare",
        verbQuranic: "non-quranic",
        pastVowel: "a",
        presentVowel: "u",
    }, 		
    {
        id: 10,
        infinitiveArabic: "بَعَثَ",
        infinitiveEnglish: "to send",
        roots: ["ب", "ع", "ث"],
        form: "I",
        weakness: "sound",
        verbRarity: "common",
        verbQuranic: "quranic",
        pastVowel: "a",
        presentVowel: "a",
    },
    {
        id: 11,
        infinitiveArabic: "بَعُدَ",
        infinitiveEnglish: "to be far (from)",
        roots: ["ب", "ع", "د"],
        form: "I",
        weakness: "sound",
        verbRarity: "common",
        verbQuranic: "non-quranic",
        pastVowel: "u",
        presentVowel: "u",
    },
    {
        id: 12,
        infinitiveArabic: "باحَثَ",
        infinitiveEnglish: "to discuss with, hold a discussion with",
        roots: ["ب", "ح", "ث"],
        form: "III",
        weakness: "sound",
        verbRarity: "rare",
        verbQuranic: "non-quranic",
    },
    {
        id: 13,
        infinitiveArabic: "بادَرَ",
        infinitiveEnglish: "to hasten",
        roots: ["ب", "د", "ر"],
        form: "III",
        weakness: "sound",
        verbRarity: "rare",
        verbQuranic: "non-quranic",
    },
    {
        id: 14,
        infinitiveArabic: "بارَكَ",
        infinitiveEnglish: "to bless",
        roots: ["ب", "ر", "ك"],
        form: "III",
        weakness: "sound",
        verbRarity: "common",
        verbQuranic: "quranic",
    },
    {
        id: 15,
        infinitiveArabic: "باشَرَ",
        infinitiveEnglish: "to be busy with, manage, direct, do directly",
        roots: ["ب", "ش", "ر"],
        form: "III",
        weakness: "sound",
        verbRarity: "common",
        verbQuranic: "non-quranic",
    },
    {
        id: 16,
        infinitiveArabic: "باعَدَ",
        infinitiveEnglish: "to keep anybody away",
        roots: ["ب", "ع", "د"],
        form: "III",
        weakness: "sound",
        verbRarity: "rare",
        verbQuranic: "non-quranic",
    },
    
    
    
    
    
        
    
    
    
    
    
    
    
]