export const optionsList = {
    form: [
        { label: "I / فَعَلَ", value: "I"},
        { label: "II / فَعَّلَ", value: "II" },
        { label: "III / فاعَلَ", value: "III"},
        { label: "IV / أَفْعَلَ", value: "IV", disabled: true },
        { label: "V / تَفَعَّلَ", value: "V", disabled: true },
        { label: "VI / تَفاعَلَ", value: "VI", disabled: true},
        { label: "VII/ اِنْفَعَلَ", value: "VII", disabled: true },
        { label: "VIII / اِفْتَعَلَ", value: "VIII", disabled: true },
        { label: "IX / اِفْعَلَّ", value: "IX", disabled: true},
        { label: "X / اِسْتَفْعَلَ", value: "X", disabled: true },
        { label: "XI / اِفْعالَّ", value: "XI", disabled: true },
        { label: "XII / اِفْعَوْعَلَ", value: "XII", disabled: true},
        { label: "XIII / اِفْعَوَّلَ", value: "XIII", disabled: true},
        { label: "XIV / اِفْعَنْلَلَ", value: "XIV", disabled: true},
        { label: "XV / اِفْعَنْلَى", value: "XV", disabled: true},
        { label: "Iq / فَعْلَقَ", value: "Iq", disabled: true},
        { label: "IIq / تَفَعْلَقَ", value: "IIq", disabled: true},
        { label: "IIIq / اِفْعَنْلَقَ", value: "IIIq", disabled: true},
        { label: "IVq / اِفْعَلَقَّ", value: "IVq", disabled: true},
    ],
    weakness: [
        { label: "Sound (e.g. ف-ع-ل)", value: "sound" },
        { label: "Assimilated (e.g. و-ج-د)", value: "assimilated", disabled: true},
        { label: "Hollow (e.g. ق-و-ل)", value: "hollow", disabled: true},
        { label: "Defective (e.g. ر-م-ي)", value: "defective", disabled: true},
        { label: "Doubled (e.g. م-د-د)", value: "doubled", disabled: true}
    ],
    verbRarity: [
        { label: "Common / عام", value: "common" },
        { label: "Uncommon / غَرِيب", value: "uncommon" },
        { label: "Rare / نادِر", value: "rare"},
    ],
    verbQuranic: [
        { label: "Quranic / قُرْآنِي", value: "quranic" },
        { label: "Non-Quranic / غَيْر قُرْآنِي", value: "non-quranic" },
    ],
    tense: [
        { label: "Perfect / الماضِي", value: "perfect" },
        { label: "Imperfect Indicative / المُضارِع المَرْفوع", value: "indicative" },
        { label: "Imperfect Subjunctive / المُضارِع المَنْصوب", value: "subjunctive"},
        { label: "Imperfect Jussive / المُضارِع المَجْزُوم", value: "jussive" },
        { label: "Imperative / صِيغَة الأَمْر", value: "imperative" },
    ],
    voice: [
        { label: "Active / صِيغَة المَعْلُوم", value: "active" },
        { label: "Passive / صِيغَة المَجْهُول", value: "passive" },
    ],
    orderBy: [
        { label: "Number / الرَقْم", value: "number" },
        { label: "Person / الضَمِير", value: "person" },
        { label: "Random / عَشْوائِي", value: "random"},
    ],
}